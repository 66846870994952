// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/components/OptimisedImage.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/components/OptimisedImage.tsx");
  import.meta.hot.lastModified = "1713789691314.1387";
}
// REMIX HMR END

export default function OptimisedImage({
  imageDetails: {
    altText,
    images
  },
  className
}) {
  const webpSrcSet = images.filter(({
    format
  }) => format === 'webp').map(({
    src,
    dpr
  }) => `${src} ${dpr}`).join(', ');
  const imageX1 = images.find(img => img.dpr === '1x' && ['png', 'jpeg'].includes(img.format));
  const imageX2 = images.find(img => img.dpr === '2x' && ['png', 'jpeg'].includes(img.format));
  return <picture className={className}>
      {webpSrcSet && <source type="image/webp" srcSet={webpSrcSet} />}
      <img src={imageX1?.src} alt={altText} srcSet={imageX2 ? `${imageX2.src} 2x` : undefined} className={className} />
    </picture>;
}
_c = OptimisedImage;
var _c;
$RefreshReg$(_c, "OptimisedImage");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;